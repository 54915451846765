<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalChangeImageDivision" :title="`${division.name} - ${$t('changeImage')}`" @shown="clearInputs">
      <div class="form-group text-center">
        <img v-if="imgPath" :src="imgPath" :alt="division.name" class="img-fluid img-thumbnail">
      </div>
      <div class="form-group">
        <label for="file">{{ $t('findImage') }} - <small class="text-muted">*.png | 300 x 300px</small></label>
        <div class="custom-file" :class="validateState('language') ? 'is-valid' : 'is-invalid'">
          <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'required|image|ext:png|size:1024'" accept=".png" name="files" :data-vv-as="$t('image') | toLowerCase">
          <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
        </div>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalChangeImageDivision')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      imgPath: null,
      files: []
    }
  },
  computed: {
    ...mapGetters(['lang', 'console', 'division', 'tournament'])
  },
  methods: {
    clearInputs () {
      this.files = []
      this.imgPath = this.division.image
      this.$refs.inputFile.value = ''
      this.$refs.lblFileName.innerHTML =  this.$t('findImage')
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = new FormData()
          payload.append('image', this.files)
          const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/change-image`
          this.$axios.post(path, payload).then(response => {
            this.clearInputs()
            this.$toastr.success(response.data.message)
            const data = {
              id: this.division.id,
              image: response.data.image
            }
            this.$store.dispatch('SET_DIVISION_IMAGE', data)
            this.$root.$emit('bv::hide::modal', 'modalChangeImageDivision')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    imagePreview (e) {
      const input = e.target  
      this.files = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = this.files.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.files = []
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    }
  }
}
</script>
